<template>
  <base-missing-data class="base-missing-data-page templates-missing-data">
    <template #icon>
      <div class="templates-missing-data__icon">
        <image-data-missing />
      </div>
    </template>
    <template #message>
      {{ message }}
    </template>
  </base-missing-data>
</template>

<script>
// Components
import BaseMissingData from '@/components/BaseMissingData.vue';
import imageDataMissing from '@/assets/imageMissingData/missing-data-contracts.svg';

export default {
  name: 'ContractsMissingData',

  components: { BaseMissingData, imageDataMissing },

  props: {
    message: {
      type: String,
      default: 'templates.missing_message',
    },
  },
};
</script>

<style lang="scss">
.templates-missing-data {
  height: 100%;
  width: 100%;

  &__icon {
    margin-bottom: 32px;

    // @media (max-width: map.get($--screens, 'xs')) {
    //   width: 164px;
    // }

    // svg {
    //   width: 100%;
    //   height: 100%;
    // }
  }
}
</style>
